import logo from "./Ransom Creek Logo.png";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RequestContainer from "./components/RequestContainer";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <img alt="logo" src={logo} width="350px" />
      {/* <SongRequest /> */}
      <div className="w-100">
        <RequestContainer />
      </div>
    </div>
  );
}

export default App;
