import React, { useRef } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";

export default function UserMessage({
  message,
  onSubmit,
  onBack,
  onUpdate,
  submitting,
}) {
  const messageRef = useRef("");

  return (
    <div>
      <Form className="d-flex flex-column gap-1 m-2 mb-4">
        <FloatingLabel label="Message">
          <Form.Control
            as="textarea"
            ref={messageRef}
            defaultValue={message}
            placeholder="song title"
            onChange={() => {
              onUpdate(messageRef.current.value);
            }}
            style={{ height: "300px" }}
          />
        </FloatingLabel>
        <div className="text-center" style={{ color: "Gray" }}>
          Include a message for the band
        </div>
      </Form>
      <div
        className={
          onBack
            ? "d-flex align-items-center justify-content-between"
            : "d-flex align-items-center justify-content-end"
        }
      >
        {onBack ? <Button onClick={() => onBack()}>back</Button> : null}
        <Button
          variant={submitting ? "secondary" : "success"}
          onClick={() => {
            onSubmit();
          }}
          disabled={submitting ? true : false}
        >
          {submitting ? "submitting..." : "submit"}
        </Button>
      </div>
    </div>
  );
}
