import React, { useRef } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";

export default function UserInfo({
  requesterName,
  requesterEmail,
  onNext,
  onPrevious,
  onUpdate,
}) {
  const requesterNameRef = useRef("");
  const requesterEmailRef = useRef("");

  return (
    <div>
      <Form className="d-flex flex-column gap-3 m-2">
        <FloatingLabel label="Your Name *required">
          <Form.Control
            type="text"
            ref={requesterNameRef}
            placeholder="your name"
            defaultValue={requesterName}
            onChange={() => {
              onUpdate(
                requesterNameRef.current.value,
                requesterEmailRef.current.value
              );
            }}
          />
        </FloatingLabel>
        <FloatingLabel label="Your Email">
          <Form.Control
            type="text"
            ref={requesterEmailRef}
            placeholder="your email"
            defaultValue={requesterEmail}
          />
        </FloatingLabel>
        <div className="text-center" style={{ color: "gray" }}>
          We'll only email you about this song suggestion.
        </div>
      </Form>
      <div
        className={
          onPrevious
            ? "d-flex align-content-center justify-items-between"
            : "d-flex align-content-center justify-content-end"
        }
      >
        <Button
          disabled={requesterName ? false : true}
          onClick={() => {
            onUpdate(
              requesterNameRef.current.value,
              requesterEmailRef.current.value
            );
            onNext();
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
