import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAbZNmN7GcoHr2MOgvtar-NcJjgnCFwbDc",
  authDomain: "bandapp-55956.firebaseapp.com",
  projectId: "bandapp-55956",
  storageBucket: "bandapp-55956.appspot.com",
  messagingSenderId: "766183485759",
  appId: "1:766183485759:web:968b18d3089e903bf4a38a",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const functions = getFunctions(app);
