import React, { useRef } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";

export default function SongDetails({ link, onNext, onBack, onUpdate }) {
  const linkRef = useRef("");

  return (
    <div>
      <Form className="d-flex flex-column gap-1 m-2 mb-4">
        <FloatingLabel label="Example">
          <Form.Control
            type="text"
            ref={linkRef}
            defaultValue={link}
            placeholder="song title"
            onChange={() => {
              onUpdate(linkRef.current.value);
            }}
          />
        </FloatingLabel>
        <div className="text-center" style={{ color: "Gray" }}>
          Share a link to the version you like. Youtube, Spotify, Instagram...
          whatever
        </div>
      </Form>
      <div
        className={
          onBack
            ? "d-flex align-items-center justify-content-between"
            : "d-flex align-items-center justify-content-end"
        }
      >
        {onBack ? <Button onClick={() => onBack()}>back</Button> : null}
        <Button
          onClick={() => {
            onUpdate(linkRef.current.value);
            onNext();
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
