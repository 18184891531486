import React, { useRef } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";

export default function SongInfo({ title, artist, onNext, onBack, onUpdate }) {
  const titleRef = useRef("");
  const artistRef = useRef("");

  return (
    <div>
      <Form className="d-flex flex-column gap-3 m-2">
        <FloatingLabel label="Song Title *required">
          <Form.Control
            type="text"
            ref={titleRef}
            defaultValue={title}
            placeholder="song title"
            onChange={() => {
              onUpdate(titleRef.current.value, artistRef.current.value);
            }}
          />
        </FloatingLabel>
        <FloatingLabel label="Performing Artist">
          <Form.Control
            type="text"
            ref={artistRef}
            placeholder="performing artist"
            defaultValue={artist}
            onChange={() => {
              onUpdate(titleRef.current.value, artistRef.current.value);
            }}
          />
        </FloatingLabel>
      </Form>
      <div
        className={
          onBack
            ? "d-flex align-items-center justify-content-between"
            : "d-flex align-items-center justify-content-end"
        }
      >
        {onBack ? <Button onClick={() => onBack()}>back</Button> : null}
        <Button
          disabled={title ? false : true}
          onClick={() => {
            onUpdate(titleRef.current.value, artistRef.current.value);
            onNext();
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
