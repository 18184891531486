import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import UserInfo from "./UserInfo";
import SongInfo from "./SongInfo";
import SongDetails from "./SongDetails";
import UserMessage from "./UserMessage";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { toast } from "react-toastify";

export default function RequestContainer() {
  const [currentPage, setCurrentPage] = useState("");
  const [currentPageCount, setCurrentPageCount] = useState(0);
  const [requesterName, setRequesterName] = useState("");
  const [requesterEmail, setRequesterEmail] = useState("");
  const [title, setTitle] = useState("");
  const [artist, setArtist] = useState("");
  const [link, setLink] = useState("");
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  // const userInfo = <UserInfo />;
  // const songInfo = <div>Song Info</div>;
  // const songDetails = <div>Song Details</div>;
  // const userMessage = <div>userMessage</div>;

  const onSubmit = async () => {
    setSubmitting(true);
    const data = {
      title: title,
      artist: artist,
      requester: requesterName,
      requesteremail: requesterEmail,
      example: link,
      approved: false,
      approvedBy: null,
      usermessage: message,
    };

    const submitSuggestion = httpsCallable(functions, "suggestSong");

    await submitSuggestion(data);

    setTitle("");
    setArtist("");
    setRequesterName("");
    setRequesterEmail("");
    setLink("");
    setMessage("");
    setSubmitting(false);
    setCurrentPageCount(0);

    toast(
      `${title} has been sent to Ransom Creek for consideration.  Thank you!`
    );
  };

  useEffect(() => {
    if (currentPageCount === 0) {
      setCurrentPage(
        <UserInfo
          onNext={() => {
            setCurrentPageCount(currentPageCount + 1);
          }}
          onUpdate={(name, email) => {
            setRequesterName(name);
            setRequesterEmail(email);
          }}
          requesterName={requesterName}
          requesterEmail={requesterEmail}
        />
      );
    } else if (currentPageCount === 1) {
      setCurrentPage(
        <SongInfo
          onNext={() => {
            setCurrentPageCount(currentPageCount + 1);
          }}
          onBack={() => {
            setCurrentPageCount(currentPageCount - 1);
          }}
          onUpdate={(title, artist) => {
            setTitle(title);
            setArtist(artist);
          }}
          title={title}
          artist={artist}
        />
      );
    } else if (currentPageCount === 2) {
      setCurrentPage(
        <SongDetails
          onNext={() => {
            setCurrentPageCount(currentPageCount + 1);
          }}
          onBack={() => {
            setCurrentPageCount(currentPageCount - 1);
          }}
          onUpdate={(link) => {
            setLink(link);
          }}
          link={link}
        />
      );
    } else {
      setCurrentPage(
        <UserMessage
          onSubmit={() => {
            onSubmit();
          }}
          onBack={() => {
            setCurrentPageCount(currentPageCount - 1);
          }}
          onUpdate={(message) => {
            setMessage(message);
          }}
          message={message}
          submitting={submitting}
        />
      );
    }
  }, [
    currentPageCount,
    requesterName,
    title,
    artist,
    link,
    message,
    requesterEmail,
    submitting,
  ]);

  return (
    <Card>
      <Card.Header className="w-100 d-flex justify-content-center align-items-center">
        Suggest a Song
      </Card.Header>
      <Card.Body>{currentPage}</Card.Body>
    </Card>
  );
}
